<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <!-- <el-input v-model="search.firmware_version_code" @change="Search" placeholder="固件版本编号"></el-input> -->
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addModel">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="固件版本编号">
        <template v-slot="scope">
          <span>{{ scope.row.firmware_version_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="区域编号">
        <template v-slot="scope">
          <span>{{ scope.row.region_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="固件版本状态">
        <template v-slot="scope">
          <span>{{ changeStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="editRegionStatus(scope.row.code)">修改</el-button>
          <el-button type="danger" size="small" @click="deleteSolid(scope.row.code)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'区域状态'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="region_code" label="区域" label-width="150px">
          <el-select v-model="form.region_code">
            <el-option
              v-for="item in regionlist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="status" label="固件版本状态" label-width="150px">
          <el-select v-model="form.status">
          <el-option label="开发版本" :value="1"></el-option>
          <el-option label="测试版本" :value="2"></el-option>
          <el-option label="上线版本" :value="3"></el-option>
          <el-option label="recall" :value="4"></el-option>
        </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
import {firmStatus} from '../../../../assets/js/dictionary'
import util from '../../../../util/util'
import api from '../../../../axios/pack'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        firmware_version_code: ''
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      edit: false,
      title: '',
      form: {
        code: '',
        firmware_version_code: '',
        region_code: '',
        status: '',
      },
      rules: {
        region_code: [{ required: true, message: '请输入区域编号', trigger: 'blur' }],
        status: [{ required: true, message: '请输入固件版本状态', trigger: 'blur' }],
      },
      regionlist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    GetRegion () {
      api.ReginList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.regionlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Search() {
      this.api.VersionStatusList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deleteSolid(item) {
      this.$confirm('是否删除该固件版本区域状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.VersionStatusDelete({code: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    goback (){
      this.$router.push('/iot/version/solid')
    },
    addModel () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
    },
    editRegionStatus (item) {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.edit = true
      this.title = '编辑'
      this.dialogFormVisible = true
      this.api.VersionStatusDetail({code:item}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    changeStatus (item) {
      return util.handleValueDisplay(firmStatus,item)
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    update() {
      this.form.firmware_version_code = this.$route.params.code
      this.api.VersionStatusUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    addconfirm () {
      this.update()
    },
    editconfirm () {
      this.update()
    },
  },
  filters: {},
  mounted () {
    this.search.firmware_version_code = this.$route.params.code
    this.Search()
  },
  created () {
    this.GetRegion()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
